import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import OSSection from "../components/other-services-section";
import ReviewSection from "../components/review-section";
import SeoHero from "../components/seo-hero";
import SeoMapSection from "../components/seo-map-section";
import SeoPageLogos from "../components/seo-page-logos";
import SeoWhatWeSolve from "../components/seo-what-we-solve";
import SeoWwaSection from "../components/seo-wwa-section";

const SEOEastbournePage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			jasonImg: wpMediaItem(title: { eq: "Jasoncorneslogo" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			seoImg: wpMediaItem(title: { eq: "SEARCH ENGINE-OPTIMISATION-IMAGE-2" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			seymourImg: wpMediaItem(title: { eq: "Seymourslogo" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			videos: allWpVideo(
				filter: { videoFields: { seoPage: { in: "Web Design Eastbourne" } } }
				sort: { fields: dateGmt, order: DESC }
			) {
				nodes {
					videoFields {
						url
						seoPage
					}
					title
				}
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			foehImg: wpMediaItem(title: { eq: "capitaltocoast-fullpage" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			wpSeoPage(title: { eq: "SEO Eastbourne" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}
			allWpPost(
				filter: { blogFields: { seoPage: { in: "SEO Eastbourne" } } }
				sort: { fields: dateGmt, order: DESC }
			) {
				nodes {
					slug
					excerpt
					title
					dateGmt
					blogFields {
						category
						keywords
						seoTitle
						featuredImage {
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "SEO Eastbourne",
				item: {
					url: `${siteUrl}/seo-eastbourne`,
					id: `${siteUrl}/seo-eastbourne`,
				},
			},
		],
	};
	function convertToSlug(Text) {
		return Text.toLowerCase()
			.replace(/ /g, "-")
			.replace(/[^\w-]+/g, "");
	}

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/seo-eastbourne`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<SeoHero
						title={`SEO \n<span class="text-secondary">Eastbourne</span>`}
						description={
							<span>
								Result-Driven Search Engine Optimisation services for local
								Eastbourne businesses.
							</span>
						}
						buttons={[
							<Button
								className="px-4 py-2 bg-white border-white  w-100 cta-btn w-md-auto mt-md-4 me-md-3 fs-5 blue-link-yellow"
								variant="primary"
								href="#form"
							>
								Talk to an expert
							</Button>,
							<Button
								as={Link}
								className="px-5 py-2 mt-4 border-white  w-100 cta-btn w-md-auto fs-5 white-link"
								variant="primary"
								to="/seo-audit-eastbourne"
							>
								Audit my website
							</Button>,
						]}
					/>
				</section>

				<SeoWwaSection
					spanXl={6}
					spanLg={8}
					smallHeading="SEO Agency in Eastbourne"
					heading="Are you looking to generate more revenue through your website?"
					text={
						<div>
							<p>
								We work closely with clients across various industries – whether
								they're a local business or an international organisation.
							</p>
							<p>
								As a <strong>top SEO company in Eastbourne</strong>, our main
								focus is our client's websites, considering them the most
								important aspect of their online presence.
							</p>
							<p>
								Our result-driven team get started straight away to find new
								ways to bring long term business growth.
							</p>
						</div>
					}
					buttonText="Talk to an expert"
					buttonUrl="#form"
					noImages
				/>
				<section className="pb-5 pb-lg-7">
					<SeoPageLogos heading={null} page="SEO Eastbourne" />
				</section>

				<SeoWhatWeSolve
					buttonText="Audit my website"
					buttonUrl="/seo-audit-eastbourne"
					heading={<span>Experts in Local SEO for Eastbourne companies</span>}
					image={data.foehImg.gatsbyImage}
					imageAlt={data.foehImg.altText}
					imgHeight="35rem"
					last
					text={
						<div>
							<h3 className="text-primary fs-2 ">
								Keyword <span className="text-light-blue">research</span>
							</h3>
							<p>
								Search terms on Google for keywords you want your website and
								product or service to rank highly on. You can find the most
								relevant results quickly by typing terms like ‘local SEO' into
								Google. Make a small list, and make it searchable by hand.
							</p>
							<h3 className="mt-5 text-primary fs-2">
								Proven strategies that work across all{" "}
								<span className="text-light-blue">search engines</span>
							</h3>
							<p>
								Whilst Google accounts for over 90% of total searches performed
								online, we ensure that your website gets found on all of the
								different search engines such as Bing, Yandex and DuckDuckGo.
							</p>
							<h3 className="mt-5 text-primary fs-2">
								Receive your free SEO{" "}
								<span className="text-light-blue">audit</span>
							</h3>
							<p>
								We will explore the benefits of SEO for your business and how
								improving your rankings could increase the number of enquiries
								that you receive. We will identify what is needed to get your
								website higher on Google and calculate your Return on
								Investment.
							</p>
						</div>
					}
				/>
				<section
					id="results"
					style={{
						background:
							"transparent linear-gradient(52deg, #023A7F 0%, #40B4E5 100%) 0% 0% no-repeat padding-box",
					}}
					className="pb-5  pb-lg-7"
				>
					<Container>
						<Row className="py-5 align-items-center py-xl-0">
							<Col lg={{ span: 8, order: "last" }}>
								<div style={{ overflow: "hidden", borderRadius: "15px" }}>
									<GatsbyImage
										className="w-100"
										image={data.seoImg.gatsbyImage}
										alt={data.seoImg.altText}
									/>
								</div>
							</Col>
							<Col lg={4} className=" mt-lg-0">
								<h2 className="mb-4 text-white display-5">
									Result-driven SEO services
								</h2>
								<p className="text-white">
									RJM have worked with{" "}
									<strong>many local Eastbourne businesses</strong> to help them
									increase their online presence, generate more revenue and
									achieve better web results.
								</p>
								<Button
									className="px-4 py-2 mt-4  w-100 w-md-auto fs-5 primary-link"
									variant="white"
									as={Link}
									to="#form"
								>
									Talk to an expert
								</Button>
								<Button
									className="px-4 py-2 mt-4  w-100 w-md-auto ms-4 d-none d-xl-inline-block fs-5 primary-link"
									variant="white"
									as={Link}
									to="/contact-us"
								>
									Get in touch
								</Button>
							</Col>
						</Row>
						<Row className="py-5 mt-4 align-items-center our-results-seo mt-lg-0 py-lg-0 gy-5 gy-lg-0">
							<Col className="p-4 text-center" xs={12} lg={true}>
								<div className="p-4 bg-jason">
									<GatsbyImage
										className="mw-100 "
										image={data.jasonImg.gatsbyImage}
										alt={data.jasonImg.altText}
									/>
								</div>
							</Col>
							<Col lg={true}>
								<h2 className="text-center ssp-bold text-lg-start fs-3">
									Our results:
								</h2>
							</Col>
							<Col className="text-center" lg={true}>
								<p className="pb-1 mb-0 ssp-bold text-primary display-4">3</p>
								<h3 className="fs-5">Month ROI</h3>
							</Col>
							<Col className="text-center" lg={true}>
								<p className="pb-1 mb-0 ssp-bold text-primary display-4">
									200%
								</p>
								<h3 className="fs-5">Increase traffic</h3>
							</Col>
							<Col className="text-center" lg={true}>
								<p className="pb-1 mb-0 ssp-bold text-primary display-4">5x</p>
								<h3 className="fs-5">More enquiries</h3>
							</Col>
						</Row>
						<Row className="py-5 mt-5 align-items-center our-results-seo py-lg-0 gy-5 gy-lg-0">
							<Col xs={12} className="p-4 text-center" lg={true}>
								<div className="p-4 bg-black">
									<GatsbyImage
										className="mw-100 "
										image={data.seymourImg.gatsbyImage}
										alt={data.seymourImg.altText}
									/>
								</div>
							</Col>
							<Col lg={true}>
								<h2 className="text-center ssp-bold text-lg-start fs-3">
									Our results:
								</h2>
							</Col>
							<Col className="text-center" lg={true}>
								<p className="pb-1 mb-0 ssp-bold text-primary display-4">5</p>
								<h3 className="fs-5">Locations at #1</h3>
							</Col>
							<Col className="text-center" lg={true}>
								<p className="pb-1 mb-0 ssp-bold text-primary display-4">50%</p>
								<h3 className="fs-5">Increase in CTR</h3>
							</Col>
							<Col className="text-center" lg={true}>
								<p className="pb-1 mb-0 ssp-bold text-primary display-4">2x</p>
								<h3 className="fs-5">More enquiries</h3>
							</Col>
						</Row>
					</Container>
				</section>

				{/* <section className="pt-5 pb-md-5 py-lg-7 bg-light-grey">
					<ReviewSection
						heading="SEO Eastbourne Reviews"
						page="Our Web Services"
					/>
				</section> */}

				{/* <section className="py-5 py-lg-7 ">
					<Container>
						<Row>
							<Col>
								<div className="iframe-container-new">
									<iframe
										className="responsive-iframe"
										src="https://www.youtube.com/embed/1fmeuE1eVUU"
										title="YouTube video player"
										frameborder="0"
										allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowfullscreen
										loading="lazy"
									></iframe>
								</div>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section className="py-5 py-lg-7 ">
					<Container>
						<Row>
							<Col>
								<h2 className="mb-5 text-primary fs-1">
									Recent Eastbourne Web Design blog articles
								</h2>
							</Col>
						</Row>
						<Row className="g-5 gx-lg-7 h-100">
							{data.allWpPost.nodes.map((blog, index) => (
								<Col style={{ minHeight: "100%" }} lg={6}>
									<div
										className="bg-white position-relative"
										style={{
											overflow: "hidden",
											borderRadius: "20px",
											boxShadow: "0px 3px 60px #00000029",
											minHeight: "100%",
										}}
									>
										<GatsbyImage
											image={blog.blogFields.featuredImage.gatsbyImage}
											alt={blog.blogFields.featuredImage.altText}
											className="w-100 seo-blog-image"
										/>
										<div className="p-4">
											<h2 className="mb-4 fs-4 pe-lg-10 text-primary ssp-bold">
												{blog.title}
											</h2>
											<div className="w-100" style={{ height: "25px" }}></div>
											<Link
												to={`/blog/${convertToSlug(blog.blogFields.category)}/${
													blog.slug
												}`}
												className="bottom-0 pb-4 med-grey-link ssp-bold position-absolute"
											>
												READ BLOG
											</Link>
										</div>
									</div>
								</Col>
							))}
						</Row>
					</Container>
				</section>

				<section className="pt-5 pt-lg-7 pb-xl-7 bg-light-grey">
					<Container>
						<Row id="form">
							<Col className="text-center">
								{" "}
								<h2 className="mb-3 display-5 text-primary">
									Talk to an expert
								</h2>
								<p>
									Book a time and day that works for you through our online
									calendar
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="d-xl-none">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "1150px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
							<Col className="d-none d-xl-block">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "700px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
						</Row>
					</Container>
				</section>
				<SeoMapSection
					heading="Discuss your SEO requirements with us"
					mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2523.2622758441216!2d0.27970641574303434!3d50.77071147952087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47df735183be68e9%3A0x51b877a5d82a3df!2sRJM%20Digital!5e0!3m2!1sen!2suk!4v1663160300414!5m2!1sen!2suk"
				/>
				<OSSection
					link1="/training"
					text1="TRAINING"
					link3="/web-design-eastbourne"
					text3="WEB DESIGN"
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1.altText}
				/>
			</Layout>
		</>
	);
};

export default SEOEastbournePage;
